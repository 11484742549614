import { createSlice } from "@reduxjs/toolkit";

// Check if user data is available in localStorage
const savedUser = JSON.parse(localStorage.getItem("purseCustomer"));

const initialState = {
  value: savedUser || {}, // Initialize with localStorage data if available
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
      // Save the user data to localStorage whenever it's updated
      localStorage.setItem("purseCustomer", JSON.stringify(action.payload));
    },
    logoutUser: (state, action) => {
      state.value = {};
      localStorage.removeItem("purseCustomer");
    },
  },
});

export const { setUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
