import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Divider, message } from "antd";
import { accountLogin } from "../../api/auth.module";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/authSlice";

const { Paragraph } = Typography;

function Login({ setRegister }) {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogin = async (v) => {
    setLoading(true);
    try {
      const login = await accountLogin(v);
      if (login.token) {
        message.success("Login Successful");
        dispatch(setUser(login.data));
        setLoading(false);
        localStorage.setItem("purseUserToken", JSON.stringify(login.token));
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Incorrect email or password");
    }
  };
  return (
    <div>
      {err && (
        <Paragraph style={{ textAlign: "center", border: "2px solid #ef1" }}>
          {err}
        </Paragraph>
      )}
      <Form
        onFinish={handleLogin}
        name="login"
        layout="vertical"
        onChange={() => setErr("")}
      >
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
          ]}
        >
          <Input type="email" placeholder="purseuser@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password placeholder="password" />
        </Form.Item>
        <Paragraph
          style={{
            color: "#1677ff",
            textAlign: "right",
            paddingBottom: "1rem",
          }}
        >
          Forgot password?
        </Paragraph>
        <Paragraph style={{ textAlign: "center" }}>
          <Button
            htmlType="submit"
            type="primary"
            className="btn"
            block
            loading={loading}
          >
            Log In
          </Button>
        </Paragraph>
      </Form>
      <Paragraph style={{ textAlign: "center" }}>
        Don't have an account?
        <Button type="link" onClick={() => setRegister(true)}>
          Sign Up
        </Button>
      </Paragraph>
    </div>
  );
}

export default Login;
