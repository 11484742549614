import { useNavigate } from "react-router-dom";
import { Avatar, Col, Row, Typography } from "antd";
import {
  BarChartOutlined,
  QrcodeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createNavigation } from "../../redux/features/navigationSlice";

const { Paragraph } = Typography;

function BottomNav() {
  const nav = useSelector((state) => state.navigation.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      <Row
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          background: "#fff",
          padding: "0.5rem 0 0.5rem 0",
          boxShadow: "2px 2px 10px #ccc",
        }}
      >
        <Col
          span={8}
          style={{
            textAlign: "center",
            color: "#0C7C85",
          }}
        >
          <Paragraph
            style={{
              padding: "0.7rem 0 0.7rem 0",
              margin: 0,
              color: nav.path === "/transactions" ? "#0C7C85" : "#888",
              lineHeight: "1.5",
            }}
            onClick={() => {
              dispatch(
                createNavigation({
                  name: "Transactions",
                  path: "/transactions",
                })
              );
              navigate("/transactions");
            }}
          >
            <BarChartOutlined style={{ fontSize: "22px" }} />
            <br />
            Transactions
          </Paragraph>
        </Col>
        <Col
          span={8}
          style={{
            textAlign: "center",
          }}
        >
          <Avatar
            style={{
              color: nav.path === "/" ? "#fff" : "#888",
              background: nav.path === "/" ? "#0C7C85" : "",
            }}
            size={70}
            onClick={() => {
              dispatch(
                createNavigation({
                  name: "Scan QR Code",
                  path: "/",
                })
              );
              navigate("");
            }}
            icon={<QrcodeOutlined />}
          />
        </Col>
        <Col span={8} style={{ textAlign: "center", color: "#0C7C85" }}>
          <Paragraph
            style={{
              padding: "0.7rem 0 0.7rem 0",
              margin: 0,
              color: nav.path === "/settings" ? "#0C7C85" : "#888",
              lineHeight: "1.5",
            }}
            onClick={() => {
              dispatch(
                createNavigation({
                  name: "Settings",
                  path: "/settings",
                })
              );
              navigate("/settings");
            }}
          >
            <SettingOutlined style={{ fontSize: "22px" }} />
            <br />
            Settings
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
}

export default BottomNav;
