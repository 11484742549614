import { Outlet } from "react-router-dom";
import BottomNav from "./BottomNav";
import { Image } from "antd";
import purseLogo from "../../images/purse_logo_black.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function Layout() {
  const navigate = useNavigate();
  const nav = useSelector((state) => state.navigation.value);
  const user = useSelector((state) => state.auth.value);

  useEffect(() => {
    const testUser = async () => {
      if (!user.name) {
        navigate("/auth");
      }
    };
    testUser();
  }, [user.name]);

  return (
    <>
      {!user.name ? (
        <div style={{ textAlign: "center", padding: "15% 5% 5% 5%" }}>
          <Image src={purseLogo} alt="purse-logo" width={90} />{" "}
          <p style={{ fontSize: "16px", color: "#888" }}>Loading ...</p>{" "}
        </div>
      ) : (
        <div>
          <div style={{ height: "90vh", overflowY: "auto" }}>
            <Outlet />
          </div>
          <BottomNav />
        </div>
      )}
    </>
  );
}

export default Layout;
