import { Button, Modal, Typography } from "antd";
import moment from "moment";
import { updateNotifications } from "../../../api/notifications.module";
import { useState } from "react";
const { Paragraph } = Typography;
function ViewNotification({ open, handleClose, fetchNotifications }) {
  const [loading, setLoading] = useState(false);
  const { merchant, message, createdAt, id } = open.data;
  console.log(id);
  const handleUpdate = async () => {
    if (!open.data.read) {
      setLoading(true);
      const sendData = { read: true };
      await updateNotifications(id, sendData);
      await fetchNotifications();
      setLoading(false);
      handleClose();
    } else {
      handleClose();
      fetchNotifications();
    }
  };

  return (
    <Modal
      open={open.state}
      onCancel={handleClose}
      title={null}
      footer={null}
      closable={false}
      className="notification-modal"
      mask={false}
      style={{ paddingTop: "6rem" }}
    >
      <Paragraph
        style={{
          color: "#fff",
          background: "#0C7C85",
          textAlign: "center",
          width: "99.8%",
          padding: "0.5rem",
          fontSize: "14px",
          borderRadius: "5px 5px 0 0",
        }}
        strong
      >
        PAYMENT NOTIFICATION
      </Paragraph>
      <Paragraph
        style={{
          color: "#888",
          marginBottom: 0,
          fontSize: "15px",
          padding: "1rem 1rem",
        }}
      >
        {message} to {merchant.name} on {moment(createdAt).format("dddd")}{" "}
        {new Date(createdAt).toLocaleDateString()} at{" "}
        {moment(createdAt).format("hh:mmA")}
      </Paragraph>
      <Paragraph style={{ textAlign: "right", width: "100%", padding: "1rem" }}>
        <Button type="primary" onClick={() => handleUpdate()} loading={loading}>
          Close
        </Button>
      </Paragraph>
    </Modal>
  );
}

export default ViewNotification;
