import { useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  message,
  Row,
  Col,
  Checkbox,
} from "antd";

import { createAccount } from "../../api/auth.module";
const { Paragraph, Text } = Typography;
function Register({ setRegister }) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const { password, passwordTwo, name, phoneNumber, email, agreed } = values;
    setValues(values);
    if (password !== passwordTwo) {
      message.warning("The Passwords entered do not match! Try again");
    } else if (password.length < 8) {
      message.warning(
        "The password entered is too shoot, must be at least 8 characters1"
      );
    } else if (password.includes(name) || password.includes(phoneNumber)) {
      message.warning(
        "Password type not recommended, it should not contain your personal details"
      );
    } else {
      try {
        const dataLoad = {
          name,
          phoneNumber: `+234${phoneNumber}`,
          email,
          password,
          agreed,
        };
        setLoading(true);
        const dt = await createAccount(dataLoad);
        if (dt.status === 201) {
          message.success(dt.message);
          setAccountCreated(true);
          setLoading(false);
        }
        if (dt.status === 400) {
          message.warning(dt.message);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      {!accountCreated ? (
        <div>
          <Form layout="vertical" name="register" onFinish={handleSubmit}>
            <Form.Item
              label="Enter your full names"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please provide your full names!",
                },
              ]}
            >
              <Input placeholder="Enter the your full name" />
            </Form.Item>
            <Form.Item
              label="Telephone Contact"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please provide your phone number!",
                },
              ]}
            >
              <Input
                addonBefore="+234"
                placeholder="Phone number"
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              label="Personal email address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please provide the your email address!",
                },
              ]}
            >
              <Input
                placeholder="Enter a valid email address"
                type="email"
                style={{ maxWidth: "100%" }}
              />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                {" "}
                <Form.Item
                  label="Create Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please ths field is required!",
                    },
                  ]}
                >
                  <Input.Password placeholder="********" type="password" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Confirm Password"
                  name="passwordTwo"
                  rules={[
                    {
                      required: true,
                      message: "Please provide a matching password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="********" type="password" />
                </Form.Item>
              </Col>
            </Row>
            <Paragraph>
              <Form.Item valuePropName="checked" name="agreed">
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms((c) => !c)}
                >
                  By clicking here, I state that I have read and understood
                  Purse's{" "}
                  <span style={{ color: "#1FA1E2", cursor: "pointer" }}>
                    {" "}
                    Terms of Use
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "#1FA1E2", cursor: "pointer" }}>
                    Privacy Policy
                  </span>
                </Checkbox>
              </Form.Item>
            </Paragraph>
            <Paragraph style={{ textAlign: "center", padding: "1rem 0" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!acceptTerms}
                block
                loading={loading}
              >
                Submit
              </Button>
            </Paragraph>
          </Form>
          <Paragraph style={{ textAlign: "center" }}>
            Already have an account?
            <Button
              type="link"
              onClick={() => setRegister(false)}
              className="btn"
            >
              Sign In
            </Button>
          </Paragraph>
        </div>
      ) : (
        <>
          <Paragraph style={{ textAlign: "center" }}>
            <Text style={{ textAlign: "center", fontSize: "18px" }} strong>
              Congratulations!!!
            </Text>
          </Paragraph>

          <Paragraph style={{ lineHeight: 1.5, textAlign: "center" }}>
            Dear{" "}
            <Text style={{ color: "#0C7C85" }} strong>
              {values?.name},
            </Text>
            Thank you for joining the Purse Wallet community. We have sent an
            email to
            <Text style={{ color: "#0C7C85" }} strong>
              {" "}
              {values?.email},
            </Text>{" "}
            Kindly check your inbox and click on the link to verify your
            account.
          </Paragraph>
          <Paragraph style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={() => setRegister(false)}
              className="btn"
            >
              Back to Sign In
            </Button>
          </Paragraph>
        </>
      )}
    </div>
  );
}

export default Register;
