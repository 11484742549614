import { Typography, Button, message, Result, Input } from "antd";
import { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { rechargeWallet } from "../../../api/payments.module";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../redux/features/authSlice";
import SpinComponent from "../../helpers/SpinComponent";
import { getCustomerInfo } from "../../../api/auth.module";

const { Paragraph } = Typography;
function RechargeWallet(props) {
  const [amount, setAmount] = useState(0);
  const [stripe, setStripe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();
  const rePopulateUser = async () => {
    try {
      const dt = await getCustomerInfo(user.id);
      if (dt.name) {
        dispatch(setUser(dt));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const rechargeNow = async (token) => {
    setLoading(true);
    const dat = {
      customerId: user.id,
      customerName: user.name,
      amount: amount * 100,
      token,
    };
    try {
      const datObj = await rechargeWallet(user.id, dat);
      if (datObj.message) {
        setLoading(false);
        setFinished(true);
        rePopulateUser();
        message.success("Recharge successful");
      }
    } catch (error) {
      setLoading(false);
      message.error("Oops, An error happened, try again later!");
      console.log(error);
    }
  };
  return (
    <div style={{ padding: "10vh 4vw", textAlign: "center" }}>
      {!stripe ? (
        <>
          <Paragraph style={{ padding: "0.4rem 3rem" }}>
            <Paragraph>Enter amount to recharge (USD)</Paragraph>
            <Input
              addonBefore="$"
              placeholder="0.0"
              className="input-text"
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </Paragraph>
          <Paragraph>
            To Nigerian Naira : ₦ {Math.floor(amount * 840.04)}
          </Paragraph>

          <Paragraph style={{ padding: "1rem 0" }}>
            <Button
              type="primary"
              onClick={() => setStripe(true)}
              disabled={amount < 1}
            >
              Continue
            </Button>
          </Paragraph>
        </>
      ) : (
        <div style={{ textAlign: "center", padding: "10vh 0" }}>
          {!finished ? (
            <>
              {loading ? (
                <SpinComponent title="Payment Gateways" />
              ) : (
                <>
                  <Paragraph>
                    You have entered $ {amount?.toLocaleString()}
                  </Paragraph>
                  <StripeCheckout
                    stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                    label="Pay Now"
                    name="Pay with Credit Card"
                    amount={amount * 100}
                    billingAddress={true}
                    description={`Recharge $ ${amount.toLocaleString()}`}
                    token={rechargeNow}
                  />{" "}
                </>
              )}
            </>
          ) : (
            <>
              <Result
                status="success"
                title={`Recharge Successful`}
                subTitle={`You have recharged $ ${amount.toLocaleString()}`}
                extra={
                  <Button type="primary" onClick={() => navigate(-1)}>
                    Back
                  </Button>
                }
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default RechargeWallet;
