import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  message,
  Result,
  Input,
  Divider,
  Space,
  Image,
} from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QrCodeReader from "react-qrcode-reader";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { checkUrl } from "../../api/qr.module";
import { createTransaction } from "../../api/payments.module";
import SpinComponent from "../helpers/SpinComponent";
import AvatarContainer from "../helpers/AvatarContainer";

const { Paragraph, Text } = Typography;

function ScanModal({ open, handleClose }) {
  const [result, setResult] = useState("");
  const [amount, setAmount] = useState({ value: 0, reason: "" });
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [paid, setPaid] = useState(false);
  const [qrId, setQrId] = useState("");
  const [qrMode, setQrMode] = useState(true);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.value);
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRead = async (code) => {
    console.log("cod", code);
    setLoading(true);
    if (!qrId) {
      const parts = code.split("/"); // Split the URL by slashes
      const id = parts[parts.length - 1]; // Get the last part of the URL
      if (id) {
        try {
          const payUrl = await checkUrl(id);
          setResult(payUrl);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          setResult("Error");
        }
      }
    } else {
      try {
        const payUrl = await checkUrl(qrId);
        setResult(payUrl);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setResult("Error");
      }
    }
  };
  const handlePayment = async () => {
    setLoadingTwo(true);
    const dataLoad = {
      customerId: user.id,
      qrCodeId: result.id,
      amount: parseInt(amount.value),
      reason: amount.reason,
    };

    try {
      const dt = await createTransaction(dataLoad);
      if (dt.message) {
        message.success(dt.message);
        setLoadingTwo(false);
        setPaid(true);
      }
    } catch (error) {
      console.log(error);
      setLoadingTwo(false);
    }
  };
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      title={null}
      closable={false}
      style={{ textAlign: "center" }}
    >
      {paid ? (
        <>
          <Result
            status="success"
            title="Success!"
            subTitle={`A sum of ₦ ${amount.value}/= has been successfully paid to ${result.name}.`}
          />
          <Paragraph>
            <Button type="primary" onClick={() => handleClose()}>
              Back to Home
            </Button>
          </Paragraph>
        </>
      ) : (
        <>
          {!result ? (
            <div>
              {qrMode ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // height: "350", // Optional: Set a specific height for the container
                    // padding: "1rem",
                  }}
                >
                  {/* <QrCodeReader
                    delay={100}
                    width={dimensions.width}
                    height={dimensions.height}
                    onRead={handleRead}
                    // videoConstraints={{
                    //   facingMode: { exact: "environment" },
                    // }}
                  /> */}
                  <QrScanner
                    onDecode={(result) => handleRead(result)}
                    onError={(error) => console.log(error?.message)}
                    constraints={{
                      facingMode: { exact: "environment" },
                    }}
                  />
                  <Paragraph>
                    <Divider dashed>
                      <span style={{ color: "#888" }}>------ Or -----</span>
                    </Divider>
                    <Space>
                      <Button type="default" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        type="default"
                        style={{ background: "#0C7C85", color: "#fff" }}
                        onClick={() => setQrMode(false)}
                      >
                        Use QrCode ID{" "}
                      </Button>
                    </Space>
                  </Paragraph>
                </div>
              ) : (
                <>
                  <Paragraph style={{ padding: "1rem 0" }}>
                    Enter QrCode ID below{" "}
                  </Paragraph>
                  <Paragraph style={{ padding: "0.5rem 2rem" }}>
                    <Input
                      value={qrId}
                      placeholder="Enter QrCode ID of the Seller"
                      onChange={(e) => setQrId(e.target.value)}
                    />
                  </Paragraph>
                  <Paragraph style={{ paddingTop: "2rem" }}>
                    <Space size={[15]}>
                      <Button
                        onClick={() => {
                          setQrMode(true);
                          setQrId("");
                        }}
                        type="default"
                      >
                        Back to Scan
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleRead()}
                        loading={loading}
                        disabled={!qrId}
                      >
                        Check QrCode ID
                      </Button>
                    </Space>
                  </Paragraph>
                </>
              )}
            </div>
          ) : (
            <>
              {result.id ? (
                <>
                  {loading ? (
                    <Paragraph
                      style={{ padding: "3vh 0", textAlign: "center" }}
                    >
                      <SpinComponent title="transactions" />
                    </Paragraph>
                  ) : (
                    <div>
                      <Paragraph
                        style={{
                          background: "#00000020",
                          padding: "1rem 1rem",
                          borderRadius: "7px 7px 0 0",
                        }}
                      >
                        <Space size={10}>
                          {result.image ? (
                            <Image
                              src={result.image}
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "50%",
                                border: "0.2px solid #ccc",
                              }}
                              preview={false}
                            />
                          ) : (
                            <AvatarContainer name={result.name} size={30} />
                          )}
                          <div style={{ textAlign: "left", lineHeight: 1 }}>
                            <Text
                              strong
                              style={{ color: "#0C7C85", fontSize: "16px" }}
                            >
                              {result?.name}
                            </Text>
                            <br />
                            {result?.email}, {result?.phoneNumber} <br />
                            {result.location && <Text style={{color:'gray'}}>{result.location} </Text>}
                          </div>
                        </Space>
                      </Paragraph>

                      <Paragraph style={{ padding: "3vh 0", lineHeight: 2 }}>
                        <Text>Enter amount to Pay</Text>
                        <br />
                        <Input
                          addonBefore="₦"
                          type="number"
                          onChange={(e) =>
                            setAmount({ ...amount, value: e.target.value })
                          }
                          autoFocus
                          placeholder="Amount"
                          style={{
                            width: "75vw",
                            padding: "5px 10px",
                          }}
                        />
                        <br />
                        <Input
                          onChange={(e) =>
                            setAmount({ ...amount, reason: e.target.value })
                          }
                          placeholder="Payment description"
                          style={{
                            width: "70vw",
                            padding: "5px 10px",
                            margin: "1rem 0",
                          }}
                        />
                      </Paragraph>
                      <Paragraph style={{ padding: "2vh 0 6vh 0" }}>
                        {user.accountBalance < amount.value ? (
                          <Paragraph
                            style={{
                              background: "#ED4D0710",
                              border: "1px solid #ED4D07",
                              color: "#414141",
                              padding: "1rem 1rem",
                              borderRadius: "7px",
                            }}
                          >
                            Account Balance is too low. Please{" "}
                            <Text
                              style={{ color: "#0C7C85" }}
                              onClick={() => navigate("/recharge")}
                            >
                              Recharge first!!!{" "}
                            </Text>{" "}
                          </Paragraph>
                        ) : (
                          <Button
                            style={{
                              background: "#0C7C85",
                              color: "#fff",
                              padding: "5px 15px",
                            }}
                            onClick={() => handlePayment()}
                            loading={loadingTwo}
                          >
                            Pay Now
                          </Button>
                        )}
                      </Paragraph>
                    </div>
                  )}
                </>
              ) : (
                <Paragraph style={{ padding: "1rem 0" }}>
                  <Result
                    icon={
                      <WarningFilled
                        style={{ fontSize: "10em", color: "gold" }}
                      />
                    }
                    status="warning"
                    title="Unsupported QR Code!"
                    subTitle="The QR Code might be okay but not supported by Purse Wallet"
                  />
                  <Paragraph>
                    <Button
                      type="primary"
                      onClick={() => {
                        setQrMode(true);
                        setResult("");
                      }}
                    >
                      Try again
                    </Button>
                  </Paragraph>
                </Paragraph>
              )}
            </>
          )}{" "}
        </>
      )}
    </Modal>
  );
}

export default ScanModal;
