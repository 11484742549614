import {
  Typography,
  Space,
  Row,
  Col,
  Divider,
  Empty,
  Input,
  Button,
  Image,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  SendOutlined,
  MessageFilled,
  CheckOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getBiChats, postChat } from "../../../../api/chats.module";
import { useEffect, useState } from "react";
import SpinComponent from "../../../helpers/SpinComponent";
import TimeAgo from "react-timeago";
import AvatarContainer from "../../../helpers/AvatarContainer";
const { Text, Paragraph } = Typography;
const { TextArea } = Input;
function MessageView() {
  const [chats, setChats] = useState("");
  const [message, setMessage] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const fetchChats = async () => {
    try {
      const getChats = await getBiChats(user.id, id);
      if (getChats) {
        setChats(getChats);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchChats();
  }, [user.id]);
  const createChat = async () => {
    setLoading(true);
    try {
      const dataLoad = {
        senderId: user.id,
        recipientId: id,
        message,
      };
      const dt = await postChat(dataLoad);
      if (dt) {
        setLoading(false);
        await fetchChats();
        setMessage("");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const options = {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
    // timeZone: "UTC", // Set the time zone to UTC
  };

  const groupedChats =
    chats &&
    chats.chats.reduce((grouped, chat) => {
      // Extract the date from the 'createdAt' field
      const date = chat.createdAt.split("T")[0];
      // Add the chat to the corresponding date in the object
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(chat);
      return grouped;
    }, {});
  // console.log("chats", groupedChats);
  return (
    <div>
      <Paragraph
        style={{
          padding: "2vh 5vw 2vh 1rem",
          borderBottom: "1px solid #0C7C8550",
          background: "#0C7C85",
        }}
      >
        <Space size={10}>
          <ArrowLeftOutlined
            style={{ color: "#fff", fontSize: "18px" }}
            onClick={() => navigate(-1)}
          />
          <Paragraph style={{ margin: 0 }}>
            {chats ? (
              <Space>
                {chats.merchant.image ? (
                  <Image
                    src={chats.merchant.image}
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      border: "0.2px solid #ccc",
                    }}
                    preview={false}
                  />
                ) : (
                  <AvatarContainer name={chats.merchant.name} size={40} />
                )}
                <Paragraph style={{ margin: 0, lineHeight: 0.5 }}>
                  <Text style={{ color: "#fff", fontSize: "15px" }} strong>
                    {chats.merchant.name}
                  </Text>
                  <br />
                  <Text style={{ fontSize: "10px", color: "#f1f1f1" }}>
                    Last seen{" "}
                    <TimeAgo
                      date={
                        chats.chats.filter((d) => d.senderId !== user.id)[0]
                          .createdAt
                      }
                    />
                  </Text>
                </Paragraph>
              </Space>
            ) : (
              <Text style={{ color: "#fff", fontSize: "15px" }} strong>
                Loading ...
              </Text>
            )}
          </Paragraph>
        </Space>
      </Paragraph>
      <div
        style={{
          height: "70vh",
          paddingBottom: "2rem",
          // background: "#414141",
          overflowY: "auto",
        }}
      >
        {!chats ? (
          <SpinComponent title="Chats" />
        ) : (
          <>
            {chats.length < 1 ? (
              <Empty description="No chats found!!!" />
            ) : (
              <>
                <Paragraph
                  style={{
                    padding: "0.5rem",
                    margin: "0 0.5rem",
                    textAlign: "center",
                    background: "#cccccc50",
                    color: "#F08D17",
                    borderRadius: "30px",
                    fontSize: "11px",
                  }}
                >
                  Chats are encrypted with end-to-end encryption
                </Paragraph>
                {Object.keys(groupedChats)
                  .sort((a, b) => {
                    const dateA = new Date(a);
                    const dateB = new Date(b);
                    return dateA - dateB;
                  })
                  .map((date) => (
                    <div key={date}>
                      <Divider dashed>
                        <Text style={{ fontSize: "12px" }}>
                          {new Date(date).toDateString()}
                        </Text>
                      </Divider>
                      {groupedChats[date]
                        .sort((a, b) => {
                          const dateA = new Date(a.createdAt);
                          const dateB = new Date(b.createdAt);
                          return dateA - dateB;
                        })
                        .map((chat) => (
                          <Row key={chat.id}>
                            <Col
                              span={24}
                              style={{ padding: "0 0.5rem 0 0.5rem" }}
                            >
                              <div
                                className={
                                  chat.senderId === user.id ? "msg-r" : "msg-l"
                                }
                              >
                                {chat.message}
                                <Paragraph
                                  style={{
                                    margin: 0,
                                    padding: "0.2rem 0 0 0",
                                    color:
                                      chat.senderId === user.id
                                        ? "#888"
                                        : "#ccc",
                                    fontSize: "10px",
                                    textAlign: "right",
                                  }}
                                >
                                  <Space>
                                    {new Intl.DateTimeFormat(
                                      "en-US",
                                      options
                                    ).format(new Date(chat.createdAt))}
                                    {chat.senderId === user.id && (
                                      <>
                                        <CheckOutlined
                                          style={{ color: "#0C7C85" }}
                                        />
                                      </>
                                    )}
                                  </Space>
                                </Paragraph>
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          background: "#fff",
          padding: "0 0 1rem 0.7rem",
        }}
      >
        <Row className="chat-type" align="middle" gutter={12}>
          <Col span={4} style={{ textAlign: "center" }}>
            <MessageFilled style={{ fontSize: "2rem" }} />
          </Col>
          <Col span={16}>
            <TextArea
              rows={1}
              value={message}
              placeholder="Type a message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => createChat()}
              loading={loading}
              shape="circle"
              icon={<SendOutlined style={{ fontSize: "18px" }} />}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default MessageView;
