import { Typography, Space } from "antd";
import { CaretDownOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  getCustomerRecharges,
  getTransactions,
} from "../../api/payments.module";
import { useSelector } from "react-redux";
import UserTransactions from "./UserTransactions";
import { useNavigate } from "react-router-dom";
const { Paragraph, Text } = Typography;
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function Transactions(props) {
  const [transactions, setTransactions] = useState("");
  const [recharges, setRecharges] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.value);
  const findTransactions = async () => {
    try {
      const transactionList = await getTransactions(user.id);
      if (transactionList) {
        setTransactions(transactionList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const findRecharges = async () => {
    try {
      const rechargeList = await getCustomerRecharges(user.id);
      if (rechargeList) {
        setRecharges(rechargeList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    findTransactions();
    findRecharges();
  }, []);

  // console.log("trans", transactions);
  let totalOut = 0;
  transactions &&
    transactions.forEach((x) => {
      totalOut += x.amount;
    });
  let totalIn = 0;
  recharges &&
    recharges.forEach((d) => {
      totalIn += d.amount;
    });
  transactions &&
    transactions.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  return (
    <div style={{ padding: "0 0 0.1rem 0" }}>
      <Paragraph
        style={{
          padding: "2vh 5vw 2vh 1rem",
          borderBottom: "1px solid #0C7C8550",
          flex: 2000,
          left: 0,
          right: 0,
          background: "#0C7C85",
        }}
      >
        <Space size={20}>
          <ArrowLeftOutlined
            style={{ color: "#fff", fontSize: "18px" }}
            onClick={() => navigate(-1)}
          />
          <Text style={{ color: "#fff", fontSize: "15px" }} strong>
            Transactions
          </Text>
        </Space>        
      </Paragraph>
      <Paragraph style={{ lineHeight: 1.5, padding: "0.5vh 3vw 1vh 1rem" }}>
        <Space size={[5]}>
          <Text style={{ color: "#0C7C85", fontSize: "16px" }}>
            {monthNames[new Date().getMonth()]}
          </Text>
          <CaretDownOutlined style={{ color: "#0C7C85" }} />
        </Space>
        <br />
        <Space size={[20]}>
          <Text style={{ color: "#888" }}>
            In ₦ {Math.floor(totalIn)?.toLocaleString()}
          </Text>{" "}
          <Text style={{ color: "#888" }}>
            Out ₦ {Math.floor(totalOut)?.toLocaleString()}{" "}
          </Text>
        </Space>
      </Paragraph>
      <div style={{ padding: "0 0 4rem 0" }}>
        <UserTransactions transactions={transactions} />
      </div>
    </div>
  );
}

export default Transactions;
