import axios from "axios";
const { REACT_APP_API_URI } = process.env;

export async function fetchChats(id) {
  const fetchQuery = await axios.get(`${REACT_APP_API_URI}/chats/${id}`);
  return fetchQuery.data;
}

export async function postChat(data) {
  const fetchQuery = await axios.post(`${REACT_APP_API_URI}/chats`, data);
  return fetchQuery.data;
}

export async function getBiChats(customerId, merchantId) {
  const fetchQuery = await axios.get(
    `${REACT_APP_API_URI}/chats/${customerId}/${merchantId}`
  );
  return fetchQuery.data;
}
