import React from 'react';

function IdentityVerification(props) {
    return (
        <div>
            Verify your identity
        </div>
    );
}

export default IdentityVerification;