import { Outlet } from "react-router-dom";
import { Typography } from "antd";
const { Paragraph } = Typography;
function Profile() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Profile;
