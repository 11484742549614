import { Spin, Typography } from "antd";
const { Paragraph, Text } = Typography;
function SpinComponent({ title }) {
  return (
    <Paragraph
      style={{ padding: "2rem", textAlign: "center", lineHeight: 2.5 }}
    >
      <Spin size="medium" /> <br />
      <Text>Loading {title}...</Text>
    </Paragraph>
  );
}

export default SpinComponent;
