import { Typography, Image } from "antd";
import { useNavigate } from "react-router-dom";
import purseLogo from "../images/purse_logo_orange.png";

const { Paragraph } = Typography;
function StartPage() {
  const navigate = useNavigate();
  const handleAuth = () => {
    navigate("/auth");
  };
  return (
    <div style={{ height: "100vh", padding: "1vh 2vw" }}>
      <Paragraph style={{ textAlign: "center", padding: "8vh 0" }}>
        <Image
          src={purseLogo}
          alt="purse-logo"
          style={{ height: "19vh" }}
          preview={false}
        />
      </Paragraph>
      <div
        style={{
          padding: "1rem",
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <Paragraph style={{ fontSize: "18px" }} strong>
          Welcome to Purse Wallet
        </Paragraph>
        <Paragraph
          style={{
            color: "#888",
            fontSize: "15px",
            marginBottom: "5vh",
            lineHeight: 1.5,
          }}
        >
          A QR Based payment method that is seamless and secure with it's origin
          in Nigeria.
        </Paragraph>
        <Paragraph
          onClick={() => handleAuth()}
          style={{
            textAlign: "center",
            fontSize: "18px",
            background: "#ED6807",
            padding: "0.7rem 0",
            color: "#fff",
            borderRadius: "7px",
            margin: 0,
          }}
          strong
        >
          Get Started!
        </Paragraph>
      </div>
    </div>
  );
}

export default StartPage;
