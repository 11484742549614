import { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Space,
  message,
  Typography,
  Image,
  Avatar,
  Upload,
} from "antd";
import {
  ArrowLeftOutlined,
  UserOutlined,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import ImgCrop from "antd-img-crop";
import { storage } from "../../../firebase/firebase";
import { updateCustomerInfo } from "../../../api/auth.module";

const { Paragraph, Text } = Typography;
function PersonalInfo() {
  const [checked, setChecked] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  let imageUrl;
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.value);

  const handleImgUpload = async () => {
    try {
      if (imgFile !== null) {
        const imgRef = ref(storage, `/files/${v4()}`);
        await uploadBytes(imgRef, imgFile[0].originFileObj);
        imageUrl = await getDownloadURL(imgRef);
        return true;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return false;
    }
  };

  const handleSubmit = async (v) => {
    setLoading(true);
    try {
      const saveImg = await handleImgUpload();
      if (saveImg === true) {
        const dataLoad = {
          image: imageUrl,
          ...v,
        };
        console.log("dataload", dataLoad);
        try {
          const dt = await updateCustomerInfo(user.id, dataLoad);
          if (dt) {
            message.success(dt.message);
            setLoading(false);
          }
          setLoading(false);
        } catch (error) {
          console.log(error.response.data.message);
          message.error(error.response.data.message);
          setLoading(false);
        }
      } else {
        message.warning("An error happened!");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleImgSelect = (e) => {
  //   const file = e.target.files[0];
  //   const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  //   if (allowedImageTypes.includes(file.type)) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImgFile(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     message.error("Invalid file type. Please select a valid image!");
  //   }
  // };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setImgFile(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open("");
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <div>
      <Paragraph
        style={{
          padding: "2vh 5vw 2vh 1rem",
          borderBottom: "1px solid #0C7C8550",
          flex: 2000,
          background: "#0C7C85",
        }}
      >
        <Space size={20}>
          <ArrowLeftOutlined
            style={{ color: "#fff", fontSize: "18px" }}
            onClick={() => navigate(-1)}
          />
          <Text style={{ color: "#fff", fontSize: "15px" }} strong>
            Personal Info
          </Text>
        </Space>
      </Paragraph>
      <div style={{ padding: "0.5rem 1rem" }}>
        <Paragraph style={{ textAlign: "right", margin: 0 }}>
          {!checked && (
            <Button icon={<EditFilled />} onClick={() => setChecked(true)} />
          )}
        </Paragraph>
        <Form
          name="personal-info"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={user}
          disabled={!checked}
        >
          <Paragraph style={{ textAlign: "right" }}>
            {checked && (
              <Space>
                <Button type="dashed" onClick={() => setChecked((c) => !c)}>
                  Discard
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Space>
            )}
          </Paragraph>
          {!checked ? (
            <Paragraph style={{ textAlign: "center" }}>
              {user.image ? (
                <Image
                  src={user.image}
                  style={{
                    width: "130px",
                    height: "130px",
                    borderRadius: "50%",
                  }}
                  alt="pic"
                  preview={false}
                />
              ) : (
                <Avatar size={90} icon={<UserOutlined />} />
              )}
            </Paragraph>
          ) : (
            <Paragraph style={{ textAlign: "center" }}>
              <ImgCrop rotationSlider cropShape="round" quality={1}>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length < 1 && (
                    <div style={{ textAlign: "center" }}>
                      <PlusOutlined style={{ fontSize: "20px" }} /> <br />{" "}
                      Upload Photo
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Paragraph>
          )}

          <Form.Item label="Full Names" name="name">
            <Input placeholder="e.g. Jane Doe" className="input-text" />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input
              placeholder="e.g. user@gmail.com"
              className="input-text"
              disabled
            />
          </Form.Item>
          <Form.Item label="Phone Number" name="phoneNumber">
            <Input placeholder="country" className="input-text" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default PersonalInfo;
