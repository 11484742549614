import axios from "axios";
const { REACT_APP_API_URI } = process.env;

export async function fetchNotifications(id) {
  const fetchQuery = await axios.get(
    `${REACT_APP_API_URI}/notifications/${id}`
  );
  return fetchQuery.data;
}
export async function updateNotifications(id, dat) {
  const res = await axios.patch(
    `${REACT_APP_API_URI}/notifications/${id}`,
    dat
  );
  return res.data;
}