import React from "react";
// import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import Layout from "./components/account/Layout";
import StartPage from "./components/StartPage";
import ScanPage from "./components/account/ScanPage";
import Transactions from "./components/transactions/Transactions";
import Profile from "./components/account/Profile";
import AuthUser from "./components/auth/AuthUser";
import RechargeWallet from "./components/account/profile/RechargeWallet";
import HomeProfile from "./components/account/profile/HomeProfile";
import PersonalInfo from "./components/account/profile/PersonalInfo";
import NotFound from "./NotFound";
import IdentityVerification from "./components/account/profile/IdentityVerification";
import MessageHome from "./components/account/profile/message-center/MessageHome";
import MessageView from "./components/account/profile/message-center/MessageView";
import Notifications from "./components/account/notifications/Notifications";

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#00000095",
            boxShadow: "none",
            fontFamily: "sans-serif",
          },
        }}
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" index element={<ScanPage />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/recharge" element={<RechargeWallet />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/settings" element={<Profile />}>
              <Route path="/settings" index element={<HomeProfile />} />
              <Route path="/settings/personal" element={<PersonalInfo />} />
              <Route path="/settings/messages" element={<MessageHome />} />
              <Route path="/settings/messages/:id" element={<MessageView />} />
              <Route
                path="/settings/verify-identity"
                element={<IdentityVerification />}
              />
            </Route>
          </Route>
          <Route path="/start" element={<StartPage />} />
          <Route path="/auth" element={<AuthUser />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ConfigProvider>
    </>
  );
}

export default App;
