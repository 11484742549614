import { useEffect, useState } from "react";
import { Typography, Space, Empty, Row, Col, Badge, Image } from "antd";
import { ArrowLeftOutlined, MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AvatarContainer from "../../helpers/AvatarContainer";
import { fetchNotifications } from "../../../api/notifications.module";
import { useSelector } from "react-redux";
import SpinComponent from "../../helpers/SpinComponent";
import TimeAgo from "react-timeago";
import ViewNotification from "./ViewNotification";

const { Paragraph, Text } = Typography;
function Notifications() {
  const [notifications, setNotifications] = useState("");
  const [open, setOpen] = useState({ state: false, data: {} });
  const handleClose = () => {
    setOpen({ state: false, data: {} });
  };
  const user = useSelector((state) => state.auth.value);
  const getNotifications = async () => {
    try {
      const notificationList = await fetchNotifications(user.id);
      setNotifications(notificationList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <Paragraph
        style={{
          padding: "2vh 5vw 2vh 1rem",
          borderBottom: "1px solid #0C7C8550",
          flex: 2000,
          background: "#0C7C85",
        }}
      >
        <Space size={20}>
          <ArrowLeftOutlined
            style={{ color: "#fff", fontSize: "18px" }}
            onClick={() => navigate(-1)}
          />
          <Text style={{ color: "#fff", fontSize: "15px" }} strong>
            Notifications
          </Text>
        </Space>
        <MoreOutlined
          style={{ float: "right", color: "#fff", fontSize: "18px" }}
        />
      </Paragraph>
      {notifications &&
        notifications?.filter((x) => x.read === false).length >= 1 && (
          <Row
            align="middle"
            style={{ marginBottom: "5px", padding: "0 1rem 0 0" }}
          >
            <Col span={24} style={{ textAlign: "right" }}>
              <Space size={[2]}>
                <Badge
                  count={
                    notifications &&
                    notifications?.filter((x) => x.read === false).length
                  }
                  style={{ background: "#52c41a" }}
                />
                {notifications &&
                  notifications?.filter((x) => x.read === false).length >=
                    1 && <Text>Unread</Text>}
              </Space>
            </Col>
          </Row>
        )}

      <div style={{ height: "78vh", overflowY: "auto" }}>
        {!notifications ? (
          <SpinComponent title="notifications" />
        ) : (
          <>
            {notifications.length < 1 ? (
              <Empty description="You have no notifications!" />
            ) : (
              <>
                {notifications
                  .sort((a, b) => {
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateB - dateA;
                  })
                  ?.map((x) => {
                    return (
                      <Paragraph
                        key={x.id}
                        style={{
                          padding: "0.5rem 0.5rem",
                          color: "#888",
                          margin: 0,
                          background: x.read ? "#fff" : "#21511130",
                          border: "1px solid #f1f1f1",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          // handleUpdate(x.id);
                          setOpen({ state: true, data: x });
                          // setSelected(x.id);
                        }}
                      >
                        <Space>
                          {x.merchant.image ? (
                            <Image
                              src={x.merchant.image}
                              style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                              }}
                              preview={false}
                            />
                          ) : (
                            <AvatarContainer
                              name={
                                x.merchant.name
                                  ? x.merchant.name
                                  : x.message?.slice(0, 17)
                              }
                              size={35}
                            />
                          )}

                          <Paragraph style={{ margin: 0, lineHeight: 1.2 }}>
                            <Text style={{ fontSize: "12px" }}>
                              {x.message}{" "}
                              {x.merchant.name && (
                                <Text>
                                  {" "}
                                  to{" "}
                                  <b style={{ color: "#0C7C85" }}>
                                    {x.merchant.name}{" "}
                                  </b>
                                </Text>
                              )}
                            </Text>
                            <br />
                            <Text
                              style={{ fontSize: "11px", color: "#F08D17" }}
                            >
                              {" "}
                              <TimeAgo date={x.createdAt} />
                            </Text>
                          </Paragraph>
                        </Space>
                      </Paragraph>
                    );
                  })}
              </>
            )}
          </>
        )}
      </div>
      <div className="notification-modal">
        {open.state && (
          <ViewNotification
            open={open}
            handleClose={handleClose}
            fetchNotifications={fetchNotifications}
          />
        )}
      </div>
    </div>
  );
}

export default Notifications;
