import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: { name: "Scan QR", path: "/" },
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    createNavigation: (state, action) => {
      state.value = action.payload;
    },
    resetNavigation: (state) => {
      state.value = { name: "Scan QR", path: "/" };
    }
  },
});

export const { createNavigation, resetNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;
