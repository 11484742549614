import { Typography, Empty, Space, Row, Col, Image } from "antd";
import SpinComponent from "../helpers/SpinComponent";
import AvatarContainer from "../helpers/AvatarContainer";
// import { useSelector } from "react-redux";

const { Paragraph, Text } = Typography;
const paraStyle = {
  borderRadius: "7px",
  background: "#cccccc30",
  padding: "1vh 4vw",
  margin: "3px 0",
};
function UserTransactions({ transactions }) {
  transactions &&
    transactions.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  return (
    <div style={{ paddingBottom: "1rem" }}>
      {!transactions ? (
        <SpinComponent title="transactions" />
      ) : (
        <>
          {transactions.length < 1 ? (
            <Empty description="You have no previous transactions!" />
          ) : (
            <>
              {transactions?.map((transaction) => (
                <Paragraph key={transaction.id} style={paraStyle}>
                  <Row align="middle" gutter={12}>
                    <Col span={16}>
                      <Space size={10}>
                        {transaction.merchant.image ? (
                          <Image
                            src={transaction.merchant.image}
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "50%",
                              border: "0.3px solid #ccc",
                            }}
                            preview={false}
                          />
                        ) : (
                          <AvatarContainer
                            size={35}
                            name={transaction.merchant?.name}
                          />
                        )}

                        <div>
                          <Paragraph
                            style={{
                              margin: "4px 0 2px 0",
                              padding: 0,
                              lineHeight: 1.2,
                              fontSize: "12px",
                            }}
                            ellipsis={{ rows: 1, expandable: false }}
                          >
                            You paid{" "}
                            <span style={{ color: "#0C7C85" }}>
                              {transaction.merchant?.name.slice(0, 15)}
                            </span>
                          </Paragraph>
                          <Paragraph
                            style={{ margin: 0, padding: 0, lineHeight: 1 }}
                          >
                            <Text style={{ color: "#888", fontSize: "10px" }}>
                              {new Date(
                                transaction.createdAt
                              )?.toLocaleString()}
                            </Text>
                          </Paragraph>
                        </div>
                      </Space>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        color: "brown",
                        fontSize: "12px",
                      }}
                    >
                      - ₦{" "}
                      {Number(transaction.amount.toFixed(2))?.toLocaleString()}
                    </Col>
                  </Row>
                </Paragraph>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UserTransactions;
