import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
function NotFound() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="404"
        title="Page Not found!!"
        subTitle={
          <Button type="primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      />
      {/* <Paragraph>
       
      </Paragraph> */}
    </div>
  );
}

export default NotFound;
