import { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import purseLogoBlack from "../../images/purse_logo_black.png";
import { Typography, Image } from "antd";

const { Text, Paragraph } = Typography;
function AuthUser() {
  const [register, setRegister] = useState(false);

  return (
    <div style={{ padding: "1rem 0" }}>
      <Paragraph style={{ textAlign: "center", marginTop: "5vh" }}>
        <Image
          src={purseLogoBlack}
          width={100}
          alt="purse-logo"
          preview={false}
        />
        <Paragraph style={{ padding: "1rem 0", lineHeight: 1 }}>
          <Text strong style={{ fontSize: "1.5rem" }}>
            {register ? "Getting started!" : "Welcome Back!"}
          </Text>
          <br />
          <Text>Enjoy cardless transactions within seconds</Text>
        </Paragraph>
      </Paragraph>

      <div style={{ margin: "2rem 2rem 0 2rem" }}>
        {register ? (
          <Register setRegister={setRegister} />
        ) : (
          <Login setRegister={setRegister} />
        )}
      </div>
    </div>
  );
}

export default AuthUser;
