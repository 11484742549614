import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Avatar,
  Image,
  Space,
  Button,
  Badge,
} from "antd";
import {
  UserOutlined,
  EyeOutlined,
  ApartmentOutlined,
  QrcodeOutlined,
  BellOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../../api/payments.module";
import { useNavigate } from "react-router-dom";
import UserTransactions from "../transactions/UserTransactions";
import ScanModal from "../scan/ScanModal";
import { createNavigation } from "../../redux/features/navigationSlice";
import { getCustomerInfo } from "../../api/auth.module";
import { setUser } from "../../redux/features/authSlice";
import { fetchNotifications } from "../../api/notifications.module";

const { Paragraph, Text } = Typography;
function ScanPage() {
  const [open, setOpen] = useState(false);
  const [transactions, setTransactions] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const getNotifications = async () => {
    try {
      const notificationList = await fetchNotifications(user.id);
      setNotifications(notificationList);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.value);
  const handleClose = async () => {
    setOpen(false);
    findTransactions();
    getNotifications();
  };
  const findTransactions = async () => {
    setLoading(true);
    try {
      const transactionList = await getTransactions(user.id);
      if (transactionList) {
        setTransactions(transactionList);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const getUserData = async () => {
      const dt = await getCustomerInfo(user.id);
      if (dt.name) {
        dispatch(setUser(dt));
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    findTransactions();
    getNotifications();
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <Row
        align="middle"
        style={{
          padding: "0.9rem 1rem",
          // color: "#fff",
        }}
      >
        <Col span={18}>
          <Space>
            {user.image ? (
              <Image
                src={user.image}
                style={{ height: "35px", width: "35px", borderRadius: "50%" }}
                preview={false}
              />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}

            <Text strong style={{ fontSize: "14px" }}>
              Hello, {user?.name.split(" ")[0]}
            </Text>
          </Space>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Badge count={notifications.filter((d) => d.read === false).length}>
            <BellOutlined
              style={{ fontSize: "22px" }}
              onClick={() => navigate("/notifications")}
            />
          </Badge>
        </Col>
      </Row>
      <Paragraph
        style={{
          background: "#000000",
          color: "#fff",
          padding: "1rem 1rem 0 1rem",
          margin: "1rem",
          borderRadius: "7px",
        }}
      >
        <Text style={{ color: "#ccc" }}>Total Balance </Text>
        <Row>
          <Col span={12}>
            ₦{" "}
            {visible ? (
              <Text style={{ fontSize: "25px", color: "#fff" }} strong>
                {user.accountBalance
                  ? Number(user?.accountBalance.toFixed(2))?.toLocaleString()
                  : "0.00"}
              </Text>
            ) : (
              <Text style={{ fontSize: "14px", color: "#fff" }} strong>
                *********
              </Text>
            )}
          </Col>
          <Col span={12} style={{ textAlign: "right", fontSize: "20px" }}>
            {visible ? (
              <EyeInvisibleOutlined onClick={() => setVisible(false)} />
            ) : (
              <EyeOutlined onClick={() => setVisible(true)} />
            )}
          </Col>
        </Row>
        <Row gutter={24} style={{ padding: "1rem 0" }}>
          <Col span={12}>
            <Paragraph
              style={{
                textAlign: "center",
                borderRadius: "7px",
                lineHeight: 1.2,
                padding: "1rem",
                margin: "2px",
                color: "#fff",
                background: "#cccccc40",
              }}
              onClick={() => navigate("/recharge")}
            >
              <ApartmentOutlined style={{ fontSize: "25px" }} />
              <br />
              <Text style={{ color: "#ccc", fontSize: "12px" }}>
                Fund Wallet{" "}
              </Text>
            </Paragraph>
          </Col>
          <Col span={12}>
            <Paragraph
              style={{
                textAlign: "center",
                borderRadius: "7px",
                padding: "1rem",
                margin: "2px",
                lineHeight: 1.2,
                color: "#fff",
                background: "#cccccc40",
              }}
              onClick={() => setOpen(true)}
            >
              <QrcodeOutlined style={{ fontSize: "25px" }} /> <br />
              <Text style={{ color: "#ccc", fontSize: "12px" }}>
                Scan and Pay
              </Text>
            </Paragraph>
          </Col>
        </Row>
      </Paragraph>
      {open && <ScanModal open={open} handleClose={handleClose} />}
      <div style={{ padding: "0.5rem 1rem 0 1rem" }}>
        <Row align="middle" style={{ margin: "0 0 1rem 0.5rem" }}>
          <Col span={14}>
            <Text strong style={{ fontSize: "16px", color: "#245" }}>
              Recent Transactions
            </Text>
          </Col>
          <Col span={10} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                dispatch(
                  createNavigation({
                    name: "Transactions",
                    path: "/transactions",
                  })
                );
                navigate("/transactions");
              }}
            >
              View All
            </Button>
          </Col>
        </Row>
        <UserTransactions transactions={transactions} />
      </div>
    </div>
  );
}

export default ScanPage;
