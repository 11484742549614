import axios from "axios";
const { REACT_APP_API_URI } = process.env;

export const rechargeWallet = async (id, data) => {
  const dt = await axios.post(
    `${REACT_APP_API_URI}/customers/recharge-wallet/${id}`,
    data
  );
  return dt.data;
};

export const getTransactions = async (id) => {
  const dt = await axios.get(
    `${REACT_APP_API_URI}/customers/transactions/${id}`
  );
  return dt.data;
};

export const createTransaction = async (data) => {
  const dt = await axios.post(
    `${REACT_APP_API_URI}/customers/transactions`,
    data
  );
  return dt.data;
};

export const getCustomerRecharges = async (id) => {
  const dt = await axios.get(`${REACT_APP_API_URI}/customers/recharges/${id}`);
  return dt.data;
};
