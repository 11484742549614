import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Typography, Empty, Space, Image } from "antd";
import { fetchChats } from "../../../../api/chats.module";
import AvatarContainer from "../../../helpers/AvatarContainer";
import { useNavigate } from "react-router-dom";
import SpinComponent from "../../../helpers/SpinComponent";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;
function MessageHome() {
  const [chats, setChats] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.value);
  const handleClose = () => {
    setOpen(false);
  };
  function toSentenceCase(str) {
    const words = str?.toLowerCase().split(" ");
    const sentenceCaseWords = words.map(
      (word) => word.charAt(0)?.toUpperCase() + word.slice(1)
    );
    const sentenceCaseStr = sentenceCaseWords.join(" ");
    return sentenceCaseStr;
  }
  const getChats = async () => {
    try {
      const getAllCharts = await fetchChats(user.id);
      setChats(getAllCharts);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getChats();
  }, []);
  // console.log(chats);

  const newList =
    chats &&
    chats.map((chat) => {
      return {
        senderId: chat.senderId,
        name: chat.sender?.name,
        image: chat.sender?.image,
      };
    });
  // function uniqueCustomers(newLi) {
  //   return [...new Set(newLi)];
  // }
  function uniqueObjectsByProperty(objects, propertyKey) {
    const seen = new Set();
    const uniqueCustomers = [];

    for (const obj of objects) {
      const value = obj[propertyKey];
      if (!seen.has(value)) {
        seen.add(value);
        uniqueCustomers.push(obj);
      }
    }

    return uniqueCustomers;
  }
  const uniqueCustomers = uniqueObjectsByProperty(newList, "senderId");
  console.log(uniqueCustomers);
  return (
    <div>
      <Paragraph
        style={{
          padding: "2vh 5vw 2vh 1rem",
          borderBottom: "1px solid #0C7C8550",
          background: "#0C7C85",
        }}
      >
        <Space size={20}>
          <ArrowLeftOutlined
            style={{ color: "#fff", fontSize: "18px" }}
            onClick={() => navigate(-1)}
          />
          <Text style={{ color: "#fff", fontSize: "15px" }} strong>
            Messages
          </Text>
        </Space>
      </Paragraph>
      {!chats ? (
        <SpinComponent title="chats" />
      ) : (
        <>
          {chats.length < 1 ? (
            <Empty description="No Chats available" />
          ) : (
            <>
              {uniqueCustomers
                .filter((k) => k.senderId !== user.id)
                .map((x) => {
                  return (
                    <Paragraph
                      style={{
                        background: "#f1f1f170",
                        borderBottom: "0.2px solid #eee",
                        cursor: "pointer",
                        // borderRadius: "3px",
                        margin: 0,
                        padding: "0.5rem 0.2rem",
                        lineHeight: 1,
                      }}
                      key={x.senderId}
                      onClick={() => {
                        navigate(`/settings/messages/${x?.senderId}`);
                      }}
                    >
                      <Row gutter={12} align="middle">
                        <Col span={4} style={{ paddingLeft: "1rem" }}>
                          {x.image ? (
                            <Image
                              src={x.image}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "0.3px solid #ccc",
                              }}
                            />
                          ) : (
                            <AvatarContainer name={x?.name} size={40} />
                          )}
                        </Col>
                        <Col span={20} style={{ padding: "0.5rem 0.2rem" }}>
                          <Text>
                            {x.name ? toSentenceCase(x?.name) : "Missing User"}
                          </Text>
                          <br />
                        </Col>
                      </Row>
                    </Paragraph>
                  );
                })}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MessageHome;
