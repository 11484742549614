import { Avatar, Typography } from "antd";
function AvatarContainer({ name, size }) {
  return (
    <Avatar
      style={{
        backgroundColor: "#0C7C85",
        border: "1px solid #f1f1f1",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "14px",
      }}
      size={size}
    >
      {name?.split(" ")[1]?.substring(0, 1)
        ? name?.split(" ")[0].substring(0, 1) +
          name?.split(" ")[1].toUpperCase().substring(0, 1)
        : name?.split(" ")[0].substring(0, 1)}
    </Avatar>
  );
}

export default AvatarContainer;
