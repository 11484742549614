import axios from "axios";
const { REACT_APP_API_URI } = process.env;

export const accountLogin = async (data) => {
  const dt = await axios.post(`${REACT_APP_API_URI}/customers/auth`, data);
  return dt.data;
};

export const createAccount = async (data) => {
  const dt = await axios.post(`${REACT_APP_API_URI}/customers`, data);
  return dt.data;
};

export const getCustomerInfo = async (id) => {
  const dt = await axios.get(`${REACT_APP_API_URI}/customers/${id}`);
  return dt.data;
};

export const updateCustomerInfo = async (id, data) => {
  const dt = await axios.patch(`${REACT_APP_API_URI}/customers/${id}`, data);
  return dt.data;
};