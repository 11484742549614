import { Typography, Space, Row, Col, Avatar, Button } from "antd";
import {  useDispatch } from "react-redux";
import {
  InfoCircleFilled,
  CaretRightFilled,
  BellFilled,
  QuestionCircleFilled,
  MessageFilled,
  GlobalOutlined,
  ArrowLeftOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../redux/features/authSlice";
import { useState } from "react";

const { Paragraph, Text } = Typography;
function HomeProfile() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(logoutUser());
      setLoading(false);
    }, 3000);
  };
  const subMenus = [
    {
      name: "Personal Data",
      description: "Provide valid data about you",
      icon: <InfoCircleFilled style={{ fontSize: "18px" }} />,
      path: "/settings/personal",
    },
    {
      name: "Message Center",
      description: "Check messages from your favorite shops",
      icon: <MessageFilled style={{ fontSize: "18px" }} />,
      path: "/settings/messages",
    },
    {
      name: "Help Center",
      description: "Have an issue? Speak to us",
      icon: <QuestionCircleFilled style={{ fontSize: "18px" }} />,
      path: "/settings/help",
    },
    {
      name: "About",
      description: "Who we are!",
      icon: <GlobalOutlined style={{ fontSize: "18px" }} />,
      path: "/settings/about",
    },
  ];
  return (
    <div>
      <Paragraph
        style={{
          padding: "2vh 5vw 2vh 1rem",
          borderBottom: "1px solid #0C7C8550",
          flex: 2000,
          left: 0,
          right: 0,
          background: "#0C7C85",
        }}
      >
        <Space size={20}>
          <ArrowLeftOutlined
            style={{ color: "#fff", fontSize: "18px" }}
            onClick={() => navigate(-1)}
          />
          <Text style={{ color: "#fff", fontSize: "15px" }} strong>
            Settings
          </Text>
        </Space>
      </Paragraph>
      <div
        style={{
          padding: "1rem 1rem 5rem 1rem",
          height: "60vh",
          overflowY: "auto",
        }}
      >
        {subMenus.map((menu) => (
          <Row
            align="middle"
            className="menu-style"
            key={menu.name}
            onClick={() => {
              navigate(`${menu.path}`);
            }}
          >
            <Col span={20}>
              <Space size={5}>
                <Paragraph style={{ fontSize: "1rem", margin: 0 }}>
                  <Avatar icon={menu.icon} shape="square" />
                </Paragraph>{" "}
                <Paragraph style={{ lineHeight: 1.1, margin: 0 }}>
                  {menu.name} <br />{" "}
                  <Text style={{ color: "#888", fontSize: "11px" }}>
                    {menu.description}{" "}
                  </Text>
                </Paragraph>
              </Space>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <CaretRightFilled />
            </Col>
          </Row>
        ))}
        <Row className="menu-style" onClick={() => handleLogout()}>
          <Col span={20}>
            <Button
              type="link"
              icon={<LogoutOutlined style={{ fontSize: "1rem" }} />}
              loading={loading}
            >
              {" "}
              Log Out{" "}
            </Button> 
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <CaretRightFilled />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HomeProfile;
